import React from 'react'

function Card({systemItem}) {
  return (
    
<a href={systemItem.link} class="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-sm hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700" target="_blank" rel="noopener noreferrer">
    <img class="object-cover w-full rounded-t-lg h-20 md:h-40 md:w-48 md:rounded-none md:rounded-l-lg" src="https://flowbite.com/docs/images/blog/image-4.jpg" alt="" />
    <div class="flex flex-col justify-between p-3 leading-normal">
        <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{systemItem.nameSystem}</h5>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">By: Corporacion Casas, desarrollado por area de TI.</p>
    </div>
</a>

  )

}

export default Card