
import React, { useContext } from 'react'
import { Tabs } from "flowbite-react";
import Card from "./Card"
import DarkModeContext from './DarkModeContext';

function ItemTab({company}) {

  const {isDarkMode}=useContext(DarkModeContext)
  
  return (
              <div class=" flex flex-col md:flex-row justify-center px-4 mx-auto gap-12 text-center md:max-w-screen-md lg:max-w-screen-lg">
                
                <div className="flex justify-center">
                  <img src={ isDarkMode?company.logos[1]:company.logos[0]} className="h-auto md:h-fit max-w-[150px]"/>
                </div>

                <div className="flex justify-center gap-4 flex-wrap">
                {company.systems.map((item) => (<Card systemItem={item}></Card>))}
                </div>
              </div>
  )
}

export default ItemTab