import Card from "./Card";
import React, { useContext, useState } from "react";
import MyTabs from "./MyTabs";
import { Table, Tabs } from "flowbite-react";
import { BsFillLightbulbFill } from "react-icons/bs";
import { Tab } from "@headlessui/react";
import HermodLogoLight from "../assets/logos/hermod/hermod-light.png";
import MamajuanaLogoLight from "../assets/logos/mamajuana/logo-mamajuana-light.png";
import AlondraLogoLight from "../assets/logos/alondra/logo-light-alondra.png";
import AlondraLogo from "../assets/logos/alondra/logo_alondra.png";
import HermodLogo from "../assets/logos/hermod/logo_hermod.png";
import MamajuanaLogo from "../assets/logos/mamajuana/logo_mamajuana.png";
import DarkModeContext from "./DarkModeContext";
import ItemTab from "./ItemTab";
import CorpcasasLogo from "../assets/logos/corpcasas/logo-corpcasas.png";
import CorpcasasLogoLight from "../assets/logos/corpcasas/logo-corpcasas-light-v2.png";
import EcatsaLogo from "../assets/logos/ecatsa/logo-ecatsa.png";
import EcatsaLogoLight from "../assets/logos/ecatsa/logo-light-ecatsa.png";
import { TabItem } from "flowbite-react/lib/esm/components/Tab/TabItem";
import TabSection from "./TabSection";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Projects() {
  const [companies, setCompanies] = useState([
    {
      nameCompany: "Hermod",
      systems: [
        {
          nameSystem: "Sistema de Asistencia",
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://asistencia.hermod.pe/",
        },
        {
          nameSystem: "Sistema de Motorizados",
          textContent:
            "Sistema de registro de delivery diario ,registro de motos y reporte del mismo.",
          link: "https://adm.hermod.pe/login",
        },
        {
          nameSystem: "Panel Admin de Aplicativo",
          textContent:
              "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://admin.hermod.pe/login",
        },
      ],
      logos: [HermodLogo, HermodLogoLight],
    },
    {
      nameCompany: "Mamajuana",
      systems: [
        {
          nameSystem: "Sistema de Asistencia",  
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://www.asistenciamamajuana.corpcasas.com/",
        },
        {
          nameSystem: "Sistema de Ventas 2023",
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://www.vt.mamajuana.corpcasas.com/",
        },
        {
          nameSystem: "Sistema Inventario",
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://iv.corpcasas.com/login",
        },
        {
          nameSystem: "Sistema Ventas 2022",
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://vt.mamajuana2022.corpcasas.com/login",
        },
        {
          nameSystem: "Sistema de Compras x Requerimiento",
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://adm.corpcasas.com/login",
        },
        {
          nameSystem: "Pagina Oficial",
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://mamajuana.pe/login",
        },
      ],
      logos: [MamajuanaLogo, MamajuanaLogoLight],
    },

    {
      nameCompany: "Alondra",
      systems: [
        {
          nameSystem: "Sistema de Ventas Alondra Spa",
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://vt.spa.alondrabeautiful.com/",
        },
        {
          nameSystem: "Ventas de Ventas Alondra Boutique",
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://vt.boutique.alondrabeautiful.com/",
        },
        {
          nameSystem: "Pagina Oficial",
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https:/alondrabeautiful.com/",
        },
      ],
      logos: [AlondraLogo, AlondraLogoLight],
    },

    {
      nameCompany: "Corp. Casas",
      systems: [
        {
          nameSystem: "Sistema Estadistica y Reporteria BI.",
          textContent:
            "onomic growth.",
          link: "https://rp.corpcasas.com/login",
        },
        {
          nameSystem: "Sistema ADM Central",
          textContent:
            "Sistema para llevar control de facturas y boletas, registro total de todos los gastos masivos de la corporacion",
          link: "https://admcentral.corpcasas.com/login",
        },
        {
          nameSystem: "Front de Empresas  ",
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://empresas.corpcasas.com/",
        },
        {
          nameSystem: "Pagina Oficial",
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://corpcasas.com/",
        },
      ],
      logos: [CorpcasasLogo, CorpcasasLogoLight],
    },
    {
      nameCompany: "Ecatsaaaaaa",
      systems: [
        {
          nameSystem: "Sistema Asistencia",
          textContent:
            "Sistema para llevar control de facturas y boletas, registro total de todos los gastos masivos de la corporacion",
          link: "https://asistencia.ecatsa.pe/login",
        },
        {
          nameSystem: "Sistema de Ventas ",
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://vt.ecatsa.pe/login",
        },
        {
          nameSystem: "Pagina Oficial",
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://ecatsa.pe/",
        },
      ],
      logos: [EcatsaLogo, EcatsaLogoLight],
    },
   {
      nameCompany: "Orale Mano",
      systems: [
        {
          nameSystem: "Sistema de Venta",
          textContent:
            "Sistema para llevar control de facturas y boletas, registro total de todos los gastos masivos de la corporacion",
          link: "https://oralemano.corpcasas.com",
        },
        {
          nameSystem: "Requerimientos",
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://adm.oralemano.corpcasas.com/",
        },
        {
          nameSystem: "Requerimientos",
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://adm.oralemano.corpcasas.com/",
        },
      ],
      logos: [EcatsaLogo, EcatsaLogoLight],
    },
    {
      nameCompany: "Fazt Market",
      systems: [
        {
          nameSystem: "Sistema de Venta",
          textContent:
            "Sistema para llevar control de facturas y boletas, registro total de todos los gastos masivos de la corporacion",
          link: "http://vt.fastmarketmamajuana.com/",
        },
        {
          nameSystem: "Requerimientos",
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://adm.fastmarketmamajuana.com/",
        },
        {
          nameSystem: "Landing Page",
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://fastmarketmamajuana.com/",
        },
      ],
      logos: [EcatsaLogo, EcatsaLogoLight],
    },
    {
      nameCompany: "Distribuidora LyP",
      systems: [
        {
          nameSystem: "Sistema de Ventas y Facturacion",
          textContent:
            "Sistema para llevar control de facturas y boletas, registro total de todos los gastos masivos de la corporacion",
          link: "https://lyp.corpcasas.com/",
        },
        {
          nameSystem: "Power BI",
          textContent:
            "Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.",
          link: "https://app.powerbi.com/view?r=eyJrIjoiNTE1ZWY2MmYtMGEwOS00MzBiLTkyOWQtZGM1NTk0OWE1MTZmIiwidCI6IjNmN2EzYmZkLTI4YWQtNGY4ZC1hZGQwLWQ2MzI2MThjYjY2ZiJ9",
        },
        
      ],
      logos: [EcatsaLogo, EcatsaLogoLight],
    },
    
    
  ]);

  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <section class="bg-white dark:bg-gray-900" id="projects-section">
      <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
          Nuestros Sistemas
        </h1>
        <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
          Los sistemas presentados tienen como finalidad hacer reporteria, ingresos de datos, charts y accesos administrativos. 
    
        </p>
        <div class="flex flex-col justify-center mb-6 lg:mb-6 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
          <div className="w-full flex-col flex lg:mb-6 justify-center  px-2 py-8 sm:px-0">
            <Tab.Group>
              <Tab.List className="flex self-center gap-1 space-x-1 w-full max-w-lg rounded-xl bg-blue-900/20 p-1">
                {companies.map((company) => (
                  <Tab
                    key={company.nameCompany}
                    className={({ selected }) =>
                      classNames(
                        "w-full rounded-lg py-2.5 text-sm font-medium leading-5 ",
                        "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                        selected
                          ? "bg-indigo-700 text-white shadow"
                          : "text-white hover:bg-white/[0.12] hover:text-white"
                      )
                    }
                  >
                    {company.nameCompany}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels className="mt-9  flex self-center flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                {companies.map((company, idx) => (
                  <Tab.Panel key={idx}>
                    <ItemTab company={company}></ItemTab>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </div>

          
        </div>
      </div>
    </section>
  );
}

/**
 
         <Tabs.Group aria-label="Pills" style="pills" className="flex gap-4 justify-center">
            
            <Tabs.Item active={true} title="Hermod">
                <ItemTab company={companies[0]}></ItemTab>
            </Tabs.Item>
            <Tabs.Item  title="Mamajuana">
                <ItemTab company={companies[1]}></ItemTab>
            </Tabs.Item>
            <Tabs.Item  title="Alondra">
                <ItemTab company={companies[2]}></ItemTab>
            </Tabs.Item>
            <Tabs.Item  title="Corpcasas">
                <ItemTab company={companies[3]}></ItemTab>
            </Tabs.Item>

          </Tabs.Group>

 */

export default Projects;
