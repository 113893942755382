import logo from './logo.svg';
import './App.css';
import NavBar from './components/NavBar';
import Hero from './components/Hero';
import Projects from './components/Projects';
import  Footer  from './components/Footer';
import { useState } from 'react';
import DarkModeContext from './components/DarkModeContext';
function App() {

  const [isDarkMode,setDarkMode]=useState(true);

  function handleModeDark () {
    setDarkMode(!isDarkMode)
  }

  return (
    <div className={`App ${isDarkMode?"dark":""}`}>
      <DarkModeContext.Provider value={{isDarkMode, handleModeDark}}>
      <NavBar></NavBar>
      <Hero></Hero>
      <Projects></Projects>
      <Footer></Footer>
      </DarkModeContext.Provider>
    </div>
  );
}



export default App;
