
import React from 'react'

import LogoCorpcasas from "../assets/logo-corpcasas.png";

import LogoCorpcasasLight from "../assets/logo-corpcasas-light.png";

import DarkModeContext from "./DarkModeContext";

import { useContext } from "react";

function Footer() {

    const {isDarkMode,handleModeDark}=useContext(DarkModeContext)


  return (
    
<footer class="p-4 bg-white shadow md:px-6 md:py-8 dark:bg-gray-900">
    <div class="sm:flex sm:items-center sm:justify-between">
        <a href="https://flowbite.com/" class="flex items-center mb-4 sm:mb-0">
            <img src={isDarkMode?LogoCorpcasasLight:LogoCorpcasas} class="h-12 mr-3" alt="Flowbite Logo" />
            <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"></span>
        </a>
        <ul class="flex flex-wrap items-center mb-6 text-sm text-gray-500 sm:mb-0 dark:text-gray-400">
            <li>
                <a href="/" class="mr-4 hover:underline md:mr-6 ">About</a>
            </li>
            <li>
                <a href="/" class="mr-4 hover:underline md:mr-6">Privacy Policy</a>
            </li>
            <li>
                <a href="/" class="mr-4 hover:underline md:mr-6 ">Licensing</a>
            </li>
            <li>
                <a href="/" class="hover:underline">Contact</a>
            </li>
        </ul>
    </div>
    <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
    <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400"><a href="https://flowbite.com/" class="hover:underline">Made by Area TI Corpcasas</a>.</span>
</footer>

  )
}

export default Footer